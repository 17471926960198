.login-container {
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    /* Añadido */
    background-color: white;
}

.login-left {
    flex: 1;
    background-color: #A35959;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-image {
    width: 50%;
    display: block;
    margin: 0 auto;
}

.login-text {
    font-size: 24px;
    color: white;
    text-align: center;
}

.login-right {
    flex: 1;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
}

.login-logo-text {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    font-weight: bold;
}

.login-logo-subtext {
    position: absolute;
    top: 60px;
    right: 20px;
    font-size: 12px;
    /* Añade espacio en la parte superior para separarlo del logo principal */
}

.socilalinks {
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    margin-top: 20px;
    text-align: center;

}

.socilion {
    margin: 0 20px;
    /* Ajusta el espacio horizontal entre los iconos según sea necesario */
    /* Tamaño de fuente deseado para los iconos (puede ajustarlo) */
}

.login-right .socilalinks,
.holi {
    display: none;
}


.divslogin {
    
    width: 60%;
}

.divslogin p {
    text-align: left;
    color: black;
}
.divslogin h2 {
    text-align: left;
    color: black;
}

.divslogin input {
    height: 40px;
    width: 99%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid grey;
}

.btnligins{
    background-color: #A35959;
    color: white;
    border-radius: 10px;
    width: 60%;
    height: 40px;
    margin-top: 5%;
    cursor: pointer;
    border: none;
    font-size: 16px;
}
/*testo coin lineas*/
.texto-con-lineas {
    display: flex;
    width: 10%;
    align-items: center;
    text-align: center;
    margin: 10px 0;
}

.texto-con-lineas span {
    flex: 1;
    position: relative;
    text-align: center;
}

.texto-con-lineas span:before,
.texto-con-lineas span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 80%; /* Puedes ajustar el ancho según necesites */
    height: 1px;
    background-color: grey; /* Ajusta el color según prefieras */
}

.texto-con-lineas span:before {
    right: 100%;
    margin-right: 15px;
}

.texto-con-lineas span:after {
    left: 100%;
    margin-left: 15px;
}

.btnliging{
    background-color: #A35959;
    color: white;
    border-radius: 10px;
    width: 20%;
    height: 40px;
    
    cursor: pointer;
    border: none;
    font-size: 16px;
}

@media (max-width: 768px) {
    .login-left {
        display: none;
    }

    .holi {
        display: block;
        color: white;
    }

    .login-right .socilalinks {
        display: flex;
        color: white;
    }
    .btnligins{
        background-color: white;
        color: #A35959;
   
    }
    .btnliging{
        background-color: white;
        color: #A35959;
        font-size: 14px;
    }

    .login-right {
        color: white;
        flex: none;
        background-color: #A35959;
        width: 100%;
        height: 100vh;
        /* Cambiado de 100% a 100vh */
        padding: 20px;
        box-sizing: border-box;
        /* Asegura que el padding esté incluido en el ancho total */
    }

    .divslogin p {
        text-align: left;
        color: white;
        margin-bottom: 2%;
    }
    .divslogin h2 {
        text-align: left;
        color: white;
        margin-bottom: 2%;
    }
}
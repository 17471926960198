.btnedituser {
    background-color: transparent;
    border: none;
    margin-left: 5%;
    margin-top: -5%;
    cursor: pointer;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #023f81;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    z-index: 1001;
    border-radius: 5px;
  }
  
  .perfiledit {
    max-height: 200px;
    max-width: 200px;
    border-radius: 50%;
    border: 2px solid black; 

  }
   .contenido{
    background-color: #fff;
    display: flex;  
    flex-direction: column;  
    align-items: center;     
    justify-content: center; 
    padding: 10px;
    border-radius: 10px; 
   }
  .formularioeditusr {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    
  }
  
  .iputimg {
    position: relative;  
    width: 50%;
    display: flex;               
    justify-content: center;   
    align-items: center; 
  }
  
  .inpustuseredi {
    width: 50%;
    flex-direction: column;  
    align-items: center;      
    justify-content: center;
    margin-left: 2%;
  }

  
  .iputdt1 {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 8%;
    height: 25px;
    width: 80%;
    border-radius: 5px;
    border: 2px solid #023f81;
  }
  .labeltxt{
    margin-left: 8%;
    font-size: 14px;
    

  }
  
  /* Nuevas reglas CSS añadidas */
  .file-input {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1002; 
  }
  
  .btnsendeditr{
    background-color: #023f81;
    color: #fff;
    border: none;
    height: 30px;
    width: 150px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    font-weight: 500;
    text-transform: uppercase;
    transition: transform 0.3s ease;  /* Añade esta línea */

  }

  .btnsendeditr:hover {
    transform: scale(1.2);  /* Añade esta línea */
  }

  .close-button {
    position: absolute;
    top: -4%;
    right: -2%;
    background-color: #fff;
    color: red;
    font-weight: bold;
    font-size: 20px;
    border-radius: 50%;
    transition: transform 0.3s ease;  /* Añade esta línea */

    border: 2px solid red;
    cursor: pointer;
    padding: 4px 8px;
    z-index: 2;
  }
  .close-button:hover {
    background-color: red;
    color: #fff;
    transform: scale(1.2); 
  }

  .modal-content {
    position: relative; 
  }
  

  .contentstat{
    margin-left: 5%;
  }

  .statuser{
    margin-left: 5%;
    display: flex;
  }
  .contmsjs{
    background-color: #f0f0f0;
    width: 20%; 
    margin-right: 2%;
    padding: 10px;

  }

  .iconstats{
    background-color: #eff0f2;
    color: #aaaab6;
    width: 100%;
    height: 50%;
    text-align: center;
    font-size: 45px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .txtstats{
    text-align: center;
  }

  .conteostats{
    margin-top: -8%;
    font-weight: 600;
  }
  .titlestat2{
    font-size: 18px;
    font-weight: 600;

  }

  .medallasconten{
    margin-left: 5%;

  }

  .medallaitem{
    background-color: #f0f0f0;
    border-radius: 5px;
    height: 100px;
    display: flex;
    width: 50%;
    padding: 5px;
  }

  .medallaicon{
    background-color: #eff0f2;
    width: 20%;
    color: #aaaab6;
    display: flex;
    justify-content: center;  
    align-items: center; 
    font-size: 40px;
    border-radius: 5px;

  }

  .medalaldatos {
    width: 70%;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .medalaldatos p, .medalaldatos progress, .medalaldatos h2 {
    margin: 5px 0; 
  }
  .medalaldatos h2{
    font-size: 20px;
  }
  .medalaldatos p{
    font-size: 15px;
  }

  .perfiledit{
    max-width: 100%;
    max-height: 50%;
    height: 50%;
    width: 100%;
    object-fit: cover; 
    border-radius: 50px;
    margin-top: 1%;
  }


  @media (max-width: 768px) {

    
  .statuser{
    margin-left: 5%;
    display: block;
  }

  .contmsjs{
    background-color: #f0f0f0;
    width: 80%; 
    margin-right: 2%;
    padding: 10px;
    margin-bottom: 5%;

  }
  .medallaitem{
    background-color: #f0f0f0;
    border-radius: 5px;
    height: 100px;
    display: flex;
    width: 90%;
    padding: 5px;
  }
 
  .medalaldatos p, .medalaldatos progress, .medalaldatos h2 {
    margin: 2px 0; 
    font-size: 15px;
  }
  }

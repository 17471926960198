.dashboard {
  margin: 20px;
}

.perfil {
  display: flex;
  align-items: center;
  /* Alinea verticalmente la imagen y el texto */
  margin-bottom: 20px;
  margin-left: 5%;
}

.imagen-perfil {
  width: 100px;
  /* Ajusta según sea necesario */
  height: 100px;
  border-radius: 50%;
  /* Hace la imagen circular */
  margin-right: 15px;
}

.info-perfil h2,
.info-perfil p {
  margin: 0;
  /* Elimina márgenes por defecto */
}

.chats-recientes,
.chats-favoritos {
  margin-bottom: 20px;
}

.lista-chats {
  display: flex;
  height: 35%;
}

/* Añade estilos para tus cards aquí */
.chat-list-item {
  background-color: #e4e6eb;
  width: 20%;
  height: 80%;
  margin-left: 2%;
  display: flex;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  color: #030303;
  transition: transform 0.3s, width 0.3s;
  /* Agrega transiciones para transform y width */
  text-decoration: none;
  border-radius: 10px;
}

.chat-list-item:hover,
.chat-list-item.active {

  transform: scale(1.05);
  /* Hace que el elemento se agrande un 5% al pasar el mouse */
  width: 21%;
  /* Cambia el ancho al pasar el mouse */
}

.chat-list-item-name {
  display: flex;
  width: 80%;
  overflow: hidden;
  /* Esconde el contenido que se desborde */
  white-space: nowrap;
  /* Asegura que el texto no se rompa en líneas adicionales */
  text-overflow: ellipsis;
  /* Añade puntos suspensivos si el contenido se desborda */
  font-size: 15px;

}

.chat-list-item-details {
  width: 20%;
  justify-content: flex-end;
  text-align: right;
  padding-top: 10px;

}

.icnosmoviles {
  display: none;
}


/* Consulta de medios para dispositivos con un ancho de pantalla de hasta 600px */
@media (max-width: 600px) {
  .chats-favoritos {
    display: none;
    /* O puedes usar 'flex' con 'flex-direction: row;' si prefieres */
    overflow-y: auto;
    /* Permite desplazamiento horizontal si los elementos son demasiado anchos */
  }

  .chats-recientes {
    display: none;
  }

  .icnosmoviles {
    text-align: center;
    justify-content: center;
    width: 100%;
    display: block;
    align-items: center;
  }

  .likmovile {
    text-decoration: none;
    /* Elimina el subrayado del enlace */
    color: inherit;
    margin: 10px;    /* Espaciado alrededor de los íconos */

  }

  .btnnavmovile {
    background-color: #f0f0f0;
    border-radius: 24.080000000000002px;
    width: 90%;
    display: flex;
    text-decoration: none;
    color: #030303;
  }




}
.contformuuser {
  background-color: #ffffff;
  height: 97%;
  width: 99%;
  padding-top: 1%;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

.contenformu {
  background-color: transparent;
  height: 90%;
  max-width: 100%;
  max-height: 100%;
  margin-left: 1%;
  font-family: 'Poppins', sans-serif;
  display: flex;
}

.ldoizqformu {
  width: 50%;
  height: 90%;
}

.inpugrd {
  top: 116px;
  left: 164px;
  width: 501px;
  height: 43px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  color: #8a8a8a;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  outline: none;
}

.textp {
  color: #1e1f26;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 1.4%;
}

.divmo {
  width: 72%;
  height: 12%;
  display: flex;
}

.ipuntchico {
  top: 563px;
  left: 170px;
  width: 242px;
  height: 43px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  color: #8a8a8a;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  outline: none;
}

.der {
  margin-left: 8%;
}

.pgtaldo {
  margin-top: 0%;
}

.btnenvirformu {
  top: 832px;
  left: 169px;
  width: 502px;
  height: 50px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  outline: none;
  cursor: pointer;
}

.btnsendformu {
  margin-left: 1%;
  font-family: 'Poppins', sans-serif;
  margin-top: 1%;
}

.txtcategoria {
  margin-top: 1%;
  margin-bottom: 0%;
}

.btnwhtc {
  top: 668px;
  left: 709px;
  width: 120px;
  height: 37px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #1fd448;
  color: #fff6f6;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 19px;
  text-align: center;
  outline: none;
  cursor: pointer;

}

.btnmessc {
  top: 668px;
  left: 840px;
  width: 120px;
  height: 37px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #168cc7;
  color: #fff6f6;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 19px;
  text-align: center;
  outline: none;
  margin-left: 2%;
  cursor: pointer;

}

.btnmlc {
  top: 668px;
  left: 970px;
  width: 120px;
  height: 37px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #efe71b;
  color: #ffffff;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 19px;
  text-align: center;
  outline: none;
  margin-left: 2%;
  cursor: pointer;

}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.ldobtns {
  margin-top: 1%;
  width: 80%;
}

.scrollableContainer {
  max-height: 100px;
  /* O el valor que desees */
  overflow-y: auto;
}

.selectedItem {
  background-color: #168cc7;
  color: #ffff;
  border-radius: 10px;
}
.contendivtitlo {
  height: 30%;
}

.contendivtitlo h3 {
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;


}
.contendivodo {
  display: flex;
  font-size: 12px;

}


.titlediv {
  font-weight: bold;
  margin-right: 2px;
  margin-left: 10px;

}


.listaodo {
  background-color: #e4e6eb;
  border: 1px solid #f0f0f0;
  width: 98%;
  color: #030303;
  border-radius: 10px;
  height: 80px;
  margin-bottom: 1%;
  font-size: 16px;
  padding-left: 5px;
  cursor: pointer;
}
.horizontal-list {
  display: flex;

}

.list-item {
  margin: 5px;
  padding: 5px;
  background-color: #e4e6eb;
  border-radius: 10px;
  /* Otros estilos como bordes, colores de fondo, etc. */
}

.contentchats{
    display: flex;
    overflow: hidden;
    height: 99vh;
    width: 100%;
    background-color: #f0f0f0;

}

.prtechats{
    width: 23%;
   
    
}
.partechat{
    width: 76%;
    margin-left: 0.9%;
}

/* Estilos para pantallas móviles */
/* CSS para ocultar/mostrar componentes en móviles */
@media screen and (max-width: 768px) {
    .prtechat {
      display: none; /* Oculta esta sección en móviles */
    }
  

    .prtechats{
        width:100%;
       
        
    }
    /* Aquí puedes añadir más reglas si necesitas ajustar otros componentes */

    .contentchats{
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        height: 90%;
        width: 100%;
        background-color: #f0f0f0;
    
    }
  }
  
.botonNuevoMensaje{
  position: fixed;
  bottom: 10%;
  right: 3%;
  font-size: 18px;
}

.contevacio {
  width: 100%;
  background-color: #f0f2f5;
  height: 100%;
}

.contenidchar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Permite el desplazamiento del contenido si es necesario */
  box-sizing: border-box;
  background-color: #eff1f4;
  display: flex;

  /* Incluye el padding y el borde en el ancho y el alto */
}
.contenidcharwhite{
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Permite el desplazamiento del contenido si es necesario */
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;

}


.contechatcuerpo {
  width: 100%;
  height:100%;
  padding-bottom: 10%;
  
}

.contechatcuerpo-wide {
  width: 60%; /* Ancho cuando holiVisible es verdadero */
  height:100%;
  padding-bottom: 2%;

}

.stylewabg{
  background-color: #efeae2;

}
.stylefbbg, 
.styleigbg,
.stylewebbg{
  background-color: #fff;


}
.conttdtosuser{
  width: 40%;
  border-left: 0.5px solid #aaaab6; 
  /* Puedes ajustar el grosor y el color del borde según tus preferencias */
}
.content__body {
  height: 82%;
  width: 100%;
  overflow-y: auto;
}


.cbrachats{
  background-color: #f0f0f0;
  height: 60px;
  display: flex;
}

.btnopclien {
  top: 19px;
  left: 819px;
  width: 31px;
  height: 30px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #aaaab6;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: rgba(0,0,0,0);
  color: #aaaab6;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 16px;
  outline: none;
  margin-left: 10%;
  cursor: pointer;
}
.audiocon{
  overflow: hidden;
}

.auimsj{
  width: 250px;
  max-width: 250px;
}

.btnopCliente{
  display: flex;
  padding: 10px;
  width: 50%;
}

.footerChat {
  padding: 10px;
  height:5%;
  text-align: center;
  align-items: center;
  align-content: center;
}

/* Modificar el color de la barra de desplazamiento de WebKit */
.content__body::-webkit-scrollbar {
  width: 10px;
  /* Ancho de la barra de desplazamiento */
}

.content__body::-webkit-scrollbar-thumb {
  background-color: #aaaab6;
  /* Color del pulgar de la barra de desplazamiento */
  border-radius: 10px;
  /* Radio de borde del pulgar */
}

.content__body::-webkit-scrollbar-track {
  background-color: white;
  /* Color del fondo de la barra de desplazamiento */
}

.nmcecleint {
  color: #030303;
  font-size: 15px;
  line-height: 26px;
  cursor: pointer;
  transition: font-size 0.3s ease; /* Define la transición */
}

.nmcecleint:hover {
  font-size: 22px; /* Nuevo tamaño de fuente al hacer hover */
}

.chat-item {
  background-color: aqua;

}

.chat__item {

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s;
  /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal;
  /* or: normal */
  animation-timing-function: cubic-bezier(0.88,
      0.19,
      0.37,
      1.11);
  /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both;
  /* or: backwards, both, none */
  animation-delay: 0.2s;
  /* or: Xms */
}

@keyframes showIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}




.chat__item.recibed {
  flex-direction: row-reverse;
  transform-origin: left;
  white-space: "nowrap";
}


.chat__item .chat__item__content {
  padding: 17px;
  margin-right: 2px;
  border-radius: 10px 10px 0 10px;
  max-width: 40%;
  overflow: "hidden";
  margin-right: 2%;
}

.chat__item .stylewa{
  background-color: #d9fdd3;
  border: 1px solid #f0f0f0;


}

.styleweb{
  background-color: #e4e6eb;

  
}

.styleig{
  background-color: #e4e6eb;

}

.stylefb{
  background-color: #287cff;
  color: #e4e6eb;


}

.chat__item.recibed {
  flex-direction: row-reverse;
  transform-origin: left;
  white-space: "nowrap";
}

.chat__item.recibed .stylewa {
  background-color: #ffffff;
  color: #000;
  border-radius: 10px 10px 10px 0;
  max-width: 40%;
  margin-left: 2%;
}

.chat__item.recibed .styleweb {
  background-color: #95bf47;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;

  border-radius: 10px 10px 10px 0;
  max-width: 40%;
  margin-left: 2%;
}

.chat__item.recibed .styleig {
  background-color: #f8275c;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  color: white;
  border-radius: 10px 10px 10px 0;
  max-width: 40%;
  margin-left: 2%;
}


.chat__item.recibed .stylefb {
  background-color: #e4e6eb;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  color: black;
  border-radius: 10px 10px 10px 0;
  max-width: 40%;
  margin-left: 2%;
}

.chat__item.recibed .stylewa .chat__meta span {
  color: black;
}

.chat__item.recibed .stylefb .chat__meta span {
  color: black;
}


.chat__item.recibed .styleig .chat__meta span {
  color: white;
}



.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}

.chat__item__content .chat__meta span {
  font-size: 10px;
  color: #363636;
  font-weight: bold;
  user-select: none;
}

.stylefb .chat__meta span {
  font-size: 10px;
  color: white;
  font-weight: bold;
  user-select: none;
}



.imgchat {
  max-width: 100%;
  /* La imagen no superará el ancho del contenedor */
  max-height: 300px;
  height: auto;
  width: auto;
  background-color: #012a45;

}

.mediasticker {
  height: 100px;
  width: 100px;

}

/* MOOOODAAALL */
.modal__close {
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  color: red;
}

.modal__close:hover {
  background-color: red;
  color: white;

}

.modal__overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__content_img {
  position: relative;
  max-width: 75%;
  max-height: 75%;
  background-color: #012a45;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;

}

.divwhite {
  background-color: #fff;
  padding: 8px;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}


  
.zoom-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.zoom-container img {
  max-width: 100%;
  max-height: 70vh;
  width: auto;
  /* Mantiene la relación de aspecto */
  height: auto;

  /* Mantiene la relación de aspecto */
}

/*===REspuesta----*/

.response-content {
  background-color: #f0f2f5;

  color: #030303;
  display: flex;
  border-radius: 5px;
  width: 100%;
}

.response-content.recibed {
  background-color: #f0f2f5;
  color: black;
}

.divclor {
  background-color: green;
  width: 5%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.divtxtrpta {
  width: 90%;
  margin-left: 2%;
  padding: auto;
}

.divtxtrpta h2 {
  margin-left: 2%;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 25px;
}

.divtxtrpta p {
  font-size: 15px;
  margin-top: -9%;
}

.txtoimg {
  justify-content: center;
  /* Centra los elementos horizontalmente */
  align-items: center;
  /* Centra los elementos verticalmente */
  text-align: center;
  /* Centra el texto del párrafo */
  /* Altura del div, ajústalo según tus necesidades */
}

.div-img-rpta {
  display: flex;
  align-items: center;
  /* Alinea verticalmente los elementos internos */
  justify-content: flex-start ;
  /* Alinea los elementos al inicio del contenedor */
  overflow: hidden;
  /* Oculta cualquier contenido que se salga de los límites del contenedor */
  width: 100%;
  /* O ajusta según tus necesidades */
}

.div-img-rpta>div {
  margin-right: 10px;
  /* Espacio entre el icono/texto y la imagen */
}

.div-img-rpta img {
  max-height: 60px;
  /* Ajusta esto para cambiar el tamaño de la imagen */
  width: 65px;
  /* Mantiene la relación de aspecto de la imagen */
}

/*bonorerespodner */
.btnopmsj {
  background-color: transparent;
  color: #030303;
  border: none;
  margin-left: 0;
  font-size: 18px;
  transition: transform 0.3s ease, font-size 0.3s ease;
  cursor: pointer;

  /* Animación suave */
}

.btnopmsj:hover {
  font-size: 20px;
  /* Aumento del tamaño de la fuente */
  transform: scale(1.1);
  /* Ligeramente más grande */
}

.listop {
  background-color: #f0f2f5;
  list-style-type: none;
  /* Remueve los marcadores de lista */
  padding: 0;
  /* Remueve el padding por defecto de la lista */
  border-radius: 10px;
}

.listop li {
  color: #030303;
  /* Texto blanco para los elementos de la lista */
  text-decoration: none;
  /* Remueve la decoración de texto (como subrayados) */
  padding: 10px 20px;
  /* Añade algo de padding para cada elemento de la lista */
  cursor: pointer;
  border-radius: 10px;
  /* Cambia el cursor a una mano al pasar por encima, indicando que es clickeable */
}

.listop li:hover {
  background-color: var(--highlight-color);
  color: var(--text-color);
  /* Cambia el color de fondo al hacer hover para mejor interactividad */
}




.didete{
  height: 90%;
  position: relative;

}
.divunoconflex{
 display: flex;
  text-align: center;
  color: #030303;
  font-size: 20px;
}
.divtituloinf{
width: 80%;
}
.btninfot{
  width: 20%;
  justify-content: flex-end;

}

.btninfot button{
  background-color: transparent;
  border: none;
  font-size: 25px;
  margin-top: 30%;
  cursor: pointer;
}
/*Datos del cleitne*/

.dtosimagenuser {
  display: flex;
  flex-direction: column; /* Cambia la dirección del flexbox a columna */
  align-items: center;
  background-color: #fff;
  height: 30%; /* Cambié la altura al 100% para que el contenido esté centrado verticalmente */
}

.circle-img {
  margin-top: 5%;
  width: 120px; /* Ajusta el ancho y alto a tu preferencia para crear un círculo */
  height: 120px;
  border-radius: 50%; /* Hace que la imagen sea circular */
  overflow: hidden; /* Oculta cualquier parte de la imagen que salga del círculo */
  text-align: center;
}

.circle-img img {
  width: 98%; /* Ajusta la imagen al tamaño del contenedor circular */
  height: 98%;
  object-fit: cover; /* Ajusta el tamaño de la imagen para que llene el círculo sin deformarse */
}

.dtonmaeedit {
  text-align: center;
  display: flex;
  margin-top: 1%;
  height:  25px;
}
.dtonmaeedit i{
  margin-top: 40%;
  margin-right: 10%;
}

.btneditclient{
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.deodeod{
  display: flex;
 text-align: center;
 justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 0;
}



.divfechaprincipal {
  display: flex;
  height: 30px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 5px;
  user-select: none;

}

.divfecha {
  text-align: center;
  background-color: #f0f2f5;
  font-size: 11px;
  border-radius: 10px;
  padding: 2px;
  font-weight: 500;
  color: #030303;

}
.loaderdatos {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  color: #000; /* O el color que prefieras */
}

.btnsodofuncios2{
  width: 30%;
  display: flex;

}

.btniconodoo2{
  background-color: transparent;
  border: none;
  width: 23px;
  font-size: 23px;
  color: #363636;
  cursor: pointer;
  margin-left: 5%;

}

.MsgTipoUsuario{
  background-color: #023F81;
  color: #FFFFFF;
  padding: 1rem;
  border-radius: 5px;
}

.mobile-only-button {
 border: none;
 cursor: pointer;
 align-items: center;
 font-size: 20px;
}


.video-size {
  width: 300px;
  height: 197.77px;
}

.hommovile{

  display: none;
}


@media only screen and (max-width: 600px) {
  /* Estilos para pantallas menores de 600px */
  .content__body {

    max-height: 90%;
    width: 100%;
    overflow-y: auto;
  }

  .nmcecleint {
    color: #030303;
    font-size: 18px;
    line-height: 16px;
    margin-left: 1%;
    cursor: pointer;
    transition: font-size 0.3s ease; /* Define la transición */
  }
  
  .nmcecleint:hover {
    font-size: 14px; 
  }
  .btnsodofuncios2{
    display: none;
  }

  .btnopCliente{
    display: none;
  }
  .chat__item .chat__item__content {
    padding: 17px;
    margin-right: 2px;
    border-radius: 10px 10px 0 10px;
    max-width: 60%;
    overflow: "hidden";
    margin-right: 2%;
  }

  .video-size {
    width: 150px;
    height: 197.77px;
  }

  
.hommovile{
display: block;
  width: 50%;
  justify-content: flex-end;
  text-align: right; 
}

.inavhome{
  color: #030303;
  font-size: 24px; 
margin: 12px;
  cursor: pointer;
}


.conttdtosuser{
  width: 100%;
  border-left: 0.5px solid #aaaab6; 

  /* Puedes ajustar el grosor y el color del borde según tus preferencias */
}

.contechatcuerpo-wide {
display: none;

}
}
@media (min-width: 768px) {
  .mobile-only-button {
    display: none;
  }

}


@media screen and (max-height: 650px) {
  .contechatcuerpo {
    width: 100%;
    height:99%;
    padding-bottom: 10%;
    overflow: hidden;
    
  }
}


.contencastores {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 100%;
    display: flex;



}

.ldodercas {
    padding-left: 1%;
    width: 50%;
}

.ldoizqcas {

    width: 49%;


}

.selctcas {
    cursor: pointer;
    top: 129px;
    left: 172px;
    width: 502px;
    height: 36px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    color: #8c8c8c;
    font-size: 14px;
    font-family: "Poppins";
    line-height: 19px;
    outline: none;
}

.txtformu {
    color: #1e1f26;
    font-size: 16px;
    line-height: 18px;
}


.inpgrde {
    top: 301px;
    left: 174px;
    width: 501px;
    height: 43px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    color: #8a8a8a;
    font-size: 16px;
    line-height: 20px;
    outline: none;
}

.dosipt{
    display: flex;
}
.dvdos{
    margin-left: 3%;
}
.inpchico{
    top: 386px;
    left: 174px;
    width: 242px;
    height: 43px;
    padding: 0px 8px;
    border: 0 ;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.16);
    background-color: #ffffff;
    color: #8a8a8a;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    
}
.btncontent{
    text-align: center;
    margin-top: 5%;
    height: 80px;

}

.btncassend {
    cursor: pointer;
    top: 622px;
    left: 168px;
    width: 200px;
    height: 50px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.16);
    background-color: #ffffff;
    color: #787878;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    outline: none;
    transition: transform 0.3s ease; /* Agregar transición para suavizar el cambio */
}

.btncassend:hover {
    transform: scale(1.1); /* Aumentar la escala en un 10% al pasar el cursor */
}


.btncassend:disabled {
    background-color: #cccccc; /* Color de fondo para el botón desactivado */
    color: #787878; /* Color del texto para el botón desactivado */
    cursor: default; /* Cursor predeterminado para el botón desactivado */
}
/* Anular el efecto hover para botones desactivados */
.btncassend:disabled:hover {
    transform: none; /* Esto asegura que el efecto hover no se aplique */
}
.btncassend:disabled:hover {
    transform: none; /* Esto asegura que el efecto hover no se aplique */
}

.contedtos{

    width: 100%;
    display: flex;
    height: 45px;
}



.divder{
    width: 50%;
    padding-left: 2%;
}
.divder p {

font-weight: bold;

}

.divizq{

    width: 40%;
    text-align: right;

}

.divrallaz{
    border-bottom: 3px solid #000;
    border-top: 3px solid #000;
}

.divselct{
    width: 70%;
}

.panchilist{
    font-size: 13px; 
    display: flex;
    margin: 5px;
  
}

.scrollable-list {
    max-height: 140px; /* Ajusta esto según tus necesidades */
    overflow-y: auto; /* Permite el desplazamiento vertical */
    margin: 0; /* Ajuste opcional */
    padding: 0; /* Ajuste opcional */
    list-style-type: none; /* Ajuste opcional para quitar los marcadores de lista */
}
.selected-options-list{
    height: 200px;
    overflow: hidden;
 
    margin-top: 1%;
}
.titlepiezas{
    margin-top: -1%;
    font-size: 15px;
}

/* Modificar el color de la barra de desplazamiento de WebKit */
.scrollable-list::-webkit-scrollbar {
    width: 10px;
    /* Ancho de la barra de desplazamiento */
  }
  
  .scrollable-list::-webkit-scrollbar-thumb {
    background-color: #aaaab6;
    /* Color del pulgar de la barra de desplazamiento */
    border-radius: 10px;
    /* Radio de borde del pulgar */
  }
  
  .scrollable-list::-webkit-scrollbar-track {
    background-color: white;
    /* Color del fondo de la barra de desplazamiento */
  }

  /*datos de boton de tip*/
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-text {
    position: absolute;
    bottom: 100%;
    left: 10%;
    transform: translateX(-30%);
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    visibility: visible;
    opacity: 0.7;
  }

  .ocureediv{

    display: flex;
    margin-top: 2%;
    padding-left: 2%;
  }

  .ocureediv div{
    margin-right: 10%;
  }
  .labelocurre{
     font-weight: bold;
     text-transform: uppercase;
     
  }

  .ipgneerar{
    width: 60%;
    padding: 2%;

  }

  .ipgneerar input{
    margin-left: 2%;

  }

  .divflexz{
    display: flex;
  }